import React from 'react';
import { Banner as BannerType } from '~/store/services/mito/interfaces';
import { getTimestampFromUtc } from '~/utils';
import { BannerWrapper } from '..';
import Timer from '../../Timer/Timer';
import classes from './Banner.module.scss';
import Link from 'next/link';
import { useLang } from '~/store/languageContext/hooks';
interface Props {
  banner?: BannerType;
  src?: string;
}

export default function Banner(props: Props) {
  const { banner } = props;
  const { lang } = useLang();

  if (!banner) return null;
  const timestamp = getTimestampFromUtc(banner?.countdown);

  const BannerComponent = () => (
    <BannerWrapper src={banner?.background?.src} className={classes.banner}>
      <div className={classes.innerContainer}>
        <img className={classes.logo} key={banner.logo?.src} src={banner.logo?.src} alt="nft" />
        <div className={classes.flex}>
          <div className={classes.BoldText}>{banner?.title && lang && banner?.title[lang]}</div>
        </div>
        <div className={classes.author}>{banner?.author}</div>
        <div className={classes.Countdown}>
          <Timer startTime={timestamp} />
        </div>
      </div>
    </BannerWrapper>
  );

  return banner?.link?.href ? (
    <Link href={banner?.link?.href || ''}>
      <a style={{ display: 'contents' }}>
        <BannerComponent />
      </a>
    </Link>
  ) : (
    <BannerComponent />
  );
}
