import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { LanguagesTimeFormats } from '~/store/languageContext/context';
import { useLang } from '~/store/languageContext/hooks';
import classes from './Timer.module.scss';

interface Props {
  days: number;
  hours: number;
  minutes: number;
  startTime: number;
}

const Timer = (props: any) => {
  const { t, lang } = useLang();
  moment.locale(lang);
  const day = 60 * 60 * 24;
  const dateThreshold = 7 * day;
  const now = Math.floor(new Date().getTime() / 1000);
  const showCountDown = now + dateThreshold > props.startTime;
  const date = moment(props.startTime * 1000).format(LanguagesTimeFormats.large[lang]);

  const calculateTimeLeft = (timestamp: number) => {
    const difference = +new Date(timestamp * 1000) - +new Date();
    let timeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  const tl = calculateTimeLeft(parseInt(props.startTime));

  const {
    initialDays = tl.days,
    initialHours = tl.hours,
    initialMinutes = tl.minutes,
    initialSeconds = tl.seconds,
  } = props;
  const [days, setDays] = useState(initialDays);
  const [hours, setHours] = useState(initialHours);
  const [minutes, setMinutes] = useState(initialMinutes);
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(60);
        }
      }
      if (minutes === 0) {
        if (hours === 0) {
          clearInterval(myInterval);
        } else {
          setHours(hours - 1);
          setMinutes(60);
        }
      }
      if (hours === 0) {
        if (days === 0) {
          clearInterval(myInterval);
        } else {
          setDays(days - 1);
          setHours(24);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const time = {
    days: !!days && `${days} ${t('common:days')}`,
    hours: !!hours && `${hours} ${t('common:hours')}`,
    minutes: !!minutes && `${minutes} ${t('common:minutes')}`,
    seconds: !!seconds && `${seconds} ${t('common:seconds')}`,
  };

  const countdown = Object.values(time)
    ?.filter((c) => c) // Filtra los valores con 0
    ?.slice(0, 2) // Toma los 2 primeros
    ?.join(` ${t('common:and')} `); // Los concatena con un espacio

  if (props.timestamp < now) return null;

  const CountDown = () => <>{countdown}</>;

  return (
    <div className={classes.Countdown}>
      {tl.minutes === 0 && tl.seconds === 0 ? null : (
        <h4 className={classes.Countdown}>{showCountDown ? <CountDown /> : date}</h4>
      )}
    </div>
  );
};

export default Timer;
